import '../../App.css';
import React, {useEffect, useRef, useState} from 'react';
import pdfjs from 'pdfjs-dist';  // Don't remove this librray. It is needed to load pdf document
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {DIV_IDS} from '../../constants';
import gtConfig from './../../config.js';
import { loadingStyle } from "../../styles";
import { currentValueExistInContextValues, getContextValues, removeIndexFromLabel } from '../../Utils.js';
import { isTable } from '../RightPanel/TableAttributesUtil.js';
import { addAttributeKeyToChangedAttributeRef } from '../RightPanel/ChangedAttribute.js';
import { Box } from '@mui/material';

const MiddlePanel = ({selectedFile, jsonExtractionCoords, jsonTuples, selectedKey, extractedValues, onInputChange
                    , selectedText, setSelectedText, selectingDivsRefs, removeHighlightColorFromSelectingDivs, 
                    handleClearInput, pdfDoc, isLoading, searchFeatureChecked, headerHeight, setTextDivs
                    ,setDivIdText, setShowloadPdfprogress, setLoadPdfProgress, changedAttributesRef, fileType
                    , pdfContainerRef, tuplesDivIdsRefs, canvasMapForOcrRefs}) => {

    if (selectedFile != null) {
        const hasPipe = selectedFile.includes('|');
        selectedFile = hasPipe ? selectedFile.split('|')[0].trim() : selectedFile.trim();
    }
    const [currentPdfFilePath, setCurrentPdfFilePath] = useState(null);
    const [selectedDivs, setSelectedDivs] = useState(new Set());     //Current divs for selected element
    const [selectedKeyValues, setSelectedKeyValues] = useState({})
    const [isMouseDown, setIsMouseDown] = useState(false);
    const abortControllerRef = useRef(new AbortController());
    const divRefs = useRef([]);
    //const textDivRefs = useRef(new Map());
    // const [textDivs, setTextDivs] = useState(new Map());
    // const [divIdText, setDivIdText] = useState(new Map());
    
    /*
        Reset selected Divs and selectedText when selectedKey
       changes to avoid adding the same text to the new selectedKey
    */
       useEffect(() => {
        setTextDivs(new Map());
        setDivIdText(new Map());
    }, [selectedFile]);

       useEffect(() => {
        handleClearInput();
    }, [selectedKey]);

    const removeKey = (keyToRemove) => {
        const updatedArray = {...selectedKeyValues};
        delete updatedArray[keyToRemove];
        setSelectedText((prevSet) => []);
        setSelectedDivs((prevSet) => new Set([]));
        setSelectedKeyValues(updatedArray);
    };

    const addElementToList = (key, element) => {
        setSelectedKeyValues((prevKeyValuePairs) => {
            const updatedKeyValuePairs = {
                ...prevKeyValuePairs,
                [key]: [...(prevKeyValuePairs[key] || []), element],
            };
            return updatedKeyValuePairs;
        });
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsMouseDown(true);
        const highlightedDivs = selectedKeyValues[selectedKey];
        if (highlightedDivs !== undefined && highlightedDivs.length > 0) {
            removeHighlightColorFromSelectingDivs(highlightedDivs);
            removeKey(selectedKey);
        }
    };

    const handleMouseLeave = (e) => {
        handleMouseUp(e);
    }

    const handleMouseUp = (e) => {
        e.preventDefault();
        setIsMouseDown(false);
        if (!isSelectedKeyValidToContinue(selectedKey) || selectedText.size === 0) {
            selectingDivsRefs.current = new Set();
            return;
        }

        if (selectingDivsRefs.current == null || selectingDivsRefs.current.size === 0){
            return;
        }

       /**
         * Defined this paramater to trace changes in values of_series & _bond_label.
         * They are used to update value of contexts & subContexts which use these values.
         * @param {string} seriesCurrentValue The current value of changing series.
         * @param {string} seriesNewValue The new value of changed series.
         * @param {string} subContextCurrentValue The current value of changing _bond_label.
         * @param {string} subContextNewValue The new value of changed _bond_label.
       */
        let seriesCurrentValue = '';
        let seriesNewValue = '';
        let subContextCurrentValue = '';
        let subContextNewValue = '';

        const updatedMap = structuredClone(extractedValues);
        if (Array.isArray(selectedKey)) { // If selected key is a table
            addAttributeKeyToChangedAttributeRef(selectedKey[0], changedAttributesRef.current);

            const selectedTableKey = updatedMap.get(selectedKey[0]);
            const firstArrayOfSelectedArray = selectedTableKey[0];
            let selectedRow = null;
            for (let i = 1; i < firstArrayOfSelectedArray.length; i++) { // Starts from i = 1 to Skip the header
                const innerArray = firstArrayOfSelectedArray[i];
                const index = innerArray[innerArray.length - 1]; // Index has been added to the last element of array
                if (index == selectedKey[1]) {
                    selectedRow = innerArray.find(innerInnerArray => innerInnerArray.some(element => element === selectedKey[2]));
                    break;
                }
            }
            const attributeName = removeIndexFromLabel(selectedKey[0]);
            if (selectedRow) {
                if (selectedText.length === 1) {
                    if (attributeName === gtConfig.context_attribute[fileType]){
                        seriesCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        seriesCurrentValue = Array.isArray(seriesCurrentValue) ? seriesCurrentValue.join() : seriesCurrentValue;
                        seriesNewValue = selectedText.join();
                    }

                    if (attributeName === gtConfig.subContext_attribute[fileType]){
                        subContextCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        subContextCurrentValue = Array.isArray(subContextCurrentValue) ? subContextCurrentValue.join() : subContextCurrentValue;
                        subContextNewValue = selectedText.join();
                    }


                    selectedRow[0] = selectedText.join();
                } else { // selecting multiple words
                    if (attributeName === gtConfig.context_attribute[fileType]){
                        seriesCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        seriesCurrentValue = Array.isArray(seriesCurrentValue) ? seriesCurrentValue.join() : seriesCurrentValue;
                        seriesNewValue = selectedText.join('');
                        seriesNewValue = seriesNewValue.trim();
                    }

                    if (attributeName === gtConfig.subContext_attribute[fileType]){
                        subContextCurrentValue = Array.isArray(selectedRow[0]) ? selectedRow[0][0] : selectedRow[0];
                        subContextCurrentValue = Array.isArray(subContextCurrentValue) ? subContextCurrentValue.join() : subContextCurrentValue;
                        subContextNewValue = selectedText.join('');
                        subContextNewValue = seriesNewValue.trim();
                    }
                    // Adding divIds object to the end of Array data of a cell [index 2]
                    selectedRow[0] = Array.from(selectedText).join("").trim();
                    selectedRow[2] = {[DIV_IDS]: [...selectingDivsRefs.current]};
                }
            }

            if ( attributeName === gtConfig.context_attribute[fileType]){
                updateContextOrSubContextOfAttributes(gtConfig.context_attribute[fileType], updatedMap, seriesCurrentValue, seriesNewValue);
            }

            if ( attributeName === gtConfig.subContext_attribute[fileType]){
                updateContextOrSubContextOfAttributes(gtConfig.subContext_attribute[fileType], updatedMap, subContextCurrentValue, subContextNewValue);
            }

            onInputChange(updatedMap);
        } else {
            if (selectingDivsRefs.current.size > 0) {
                addAttributeKeyToChangedAttributeRef(selectedKey, changedAttributesRef.current);
                const divTextArray = [];
                selectingDivsRefs.current.forEach(divId => {
                    const divElement = document.getElementById(divId);
                    if (divElement) {
                        const divTitle = divElement.getAttribute('title');
                        divTextArray.push(divTitle.trim());
                    }
                });

                const divTextString = divTextArray.join(' ');

                // updatedMap.set(selectedKey, [Array.from(selectedText).join("").trim(), {[DIV_IDS]: [...selectingDivs]}]);
                const currentMapItem = updatedMap.get(selectedKey);
                if(currentMapItem && Array.isArray(currentMapItem) && currentMapItem.length > 1){
                    currentMapItem[0] = divTextString;
                    currentMapItem[1] = {[DIV_IDS]: [...selectingDivsRefs.current]};
                } else {
                    updatedMap.set(selectedKey, [divTextString, {[DIV_IDS]: [...selectingDivsRefs.current]}, "","", "", "","", "", "","", "", ""]);
                }
                setSelectedDivs(selectingDivsRefs.current);
                onInputChange(updatedMap);
            }
        }
    };

    const updateContextOrSubContextOfAttributes = (attribute, updatedMap, currentValue, newValue) => {
        /**
         * If user changed a context value in context_attribute(s)[_series], but still in context_attribute(s)
         * there is another value equal to current value, don't change context value of attributes.
         */
        if (attribute === gtConfig.context_attribute[fileType]){
            const valueExist = currentValueExistInContextValues(currentValue, updatedMap);
            if (valueExist){
                return;
            }
        }

        const index = (attribute === gtConfig.context_attribute[fileType] ? 9 : 10);
        if ( newValue != null && currentValue != null && newValue !== currentValue){
            const keys = updatedMap.keys();
            for (let key of keys ){
                let attribute = updatedMap.get(key);
                if (Array.isArray(attribute) && attribute.length >= index && ( attribute[index] === currentValue || attribute[index] === undefined) ){
                    if (!isTable(attribute) && attribute[0] === 'Not Found'){
                        continue;
                    }
                    attribute[index] = newValue;
                    addAttributeKeyToChangedAttributeRef(key, changedAttributesRef.current);
                }
            }
        }
        return updatedMap;
    }


    const isSelectedKeyValidToContinue = (selectedKey) => {
        /**
         * Selected Key is Array including [updatedMap key,rowIndex, selected cell name] if a cell from table is selected
         * ex: ['form_maturity_schedule[01]', 'b24df148-5722-4466-bc0a-a9d32c5c47cf', 'Date[09]']
         */
        if (Array.isArray(selectedKey)) {
            if (selectedKey[0] === null || !extractedValues.has(selectedKey[0])) {
                return false;
            }
        } else {
            if (selectedKey === null || !extractedValues.has(selectedKey)) {
                return false;
            }
        }
        return true;
    }

    const handleMouseMove = (e) => {
        e.preventDefault();
        if (isMouseDown) {
            const el_selected = e.target
            if (el_selected !== '') {
                if (!tuplesDivIdsRefs.current.has(el_selected.id)) {
                    return
                }
                const id = el_selected.id;
                let wrd = el_selected.title
                if (!selectingDivsRefs.current.has(id) && wrd !== '' ){
                    selectingDivsRefs.current.add(id);

                    const regex = /[\t\n]+/g;
                    wrd = wrd.replace(regex, ' '); //Replace \t \n with space
                    
                    setSelectedText((prevList) => [...prevList, wrd]);
                                     
                    let clonedSelectedKey = {...selectedKey}
                    if (Array.isArray(selectedKey)) {
                        clonedSelectedKey = selectedKey[2]
                    }
                    addElementToList(clonedSelectedKey, el_selected.id)
                    el_selected.style.backgroundColor = 'rgba(255, 255, 0, 0.7)';                  
                }
            }
        }
    };

    const addToTextDivRef = (attribute, divId, texDivMap, divIdTextMap) => {
        attribute = attribute.trim().toLowerCase();
        if (texDivMap.has(attribute)){
            texDivMap.get(attribute).push(divId);
        } else {
            texDivMap.set(attribute,[divId]);
        }
        divIdTextMap.set(divId, attribute);
    }

    useEffect(() => {
            tuplesDivIdsRefs.current = new Map();
            const handleMouseOver = (attribute) => {
                return () => {
                    if (!document.getElementById(attribute) == null) {
                        document.getElementById(attribute).style.backgroundColor = '#cccccc';
                        document.getElementById('text_' + attribute).style.backgroundColor = '#cccccc';
                    }
                };
            }

            const handleMouseOut = (attribute) => {
                return () => {
                    if (!document.getElementById(attribute) == null) {
                        document.getElementById(attribute).style.backgroundColor = 'white';
                        document.getElementById('text_' + attribute).style.backgroundColor = 'white';
                    }
                };
            }

            const loadPdfWithAnnotations = async () => {
                console.log("Loading pdf with Annotations.")
                const abortController = new AbortController();
                abortControllerRef.current = abortController;
                if (currentPdfFilePath !== selectedFile) {
                    console.log('Selected file has changed. Skipping loadPdfWithAnnotations.');
                    return;
                }
                document.querySelector('.middle-panel').scrollTop = 0;

                if (pdfContainerRef.current) {
                    while (pdfContainerRef.current.firstChild) {
                        pdfContainerRef.current.removeChild(pdfContainerRef.current.firstChild);
                    }
                }

                try {
                    console.log("loading pdf file")
                    const pdfjsScript = document.createElement('script');
                    pdfjsScript.src = gtConfig.pdfjsScript_src;
                    document.body.appendChild(pdfjsScript);                  

                    pdfjsScript.onload = async () => {
                        initializePdf(abortController.signal);
                    };
                } catch (error) {
                    console.log(error.message);
                }

            };

            const clearContainer = () => {
                divRefs.current = [];
              //  textDivRefs.current = new Map();
            };
            clearContainer();

            const initializePdf = async (signal) => {
                try {
                    pdfContainerRef.current.innerHTML = '';
                    let texDivMap = new Map();
                    let divIdTextMap = new Map();

                    // Render the PDF
                    setShowloadPdfprogress(true);
                    canvasMapForOcrRefs.current = new Map(); // Clear the canvasMapForOcrRefs before loading new pdf

                    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
                        setLoadPdfProgress((prevProgress) => (pageNum / pdfDoc.numPages) * 100);
                        const page = await pdfDoc.getPage(pageNum);
                        
                        if (signal.aborted) {
                            break;
                        }

                        const canvas = document.createElement('canvas'); // Create canvas to attach the pdf page
  
                        pdfContainerRef.current.appendChild(canvas);

                        let scale = 1.7;
                        
                        /* Obtain the 2D rendering context for a <canvas> element in HTML. 
                        *  It is needed to render pdf page content in the canvas and attach red/gray rectangles
                        */
                        const context = canvas.getContext('2d');
                        let viewport = null;
                        viewport = page.getViewport({scale});

                        
                        /*
                        * Ticket-ADO-47829    
                        * For this use case to fix the issue I needed to change the scale before getting viewport!
                        * viewBox: [ 666, 63.1702, 1286.22, 855.442]
                        */
                        if ( viewport.viewBox && viewport.viewBox.length > 2 && viewport.viewBox[0] != 0 && viewport.viewBox[1] != 0 && viewport.viewBox[2] > 1200){
                            scale = 1.36; //EX YY2023_MM09_05164HBA0_OS_il619.pdf (Page 1)
                            viewport = page.getViewport({scale});
                        }
                                                
        
                        /*
                            Ticket ADO-33653: In pdf 477, the first page had different viewport value.
                            That caused the annotation not to be alligned with the words in the first page.
                            Changed the scale and value of viewBox (provided same values as other pages) to fixed the issue.
                        */                
                        if (viewport.viewBox && viewport.viewBox.length > 2 && viewport.viewBox[0] !== 0 && viewport.viewBox[0] !== 0){
                            if (viewport.viewBox[0] < 1){
                                scale = 1.40;  // Ex: O83317.pdf 
                            } else {
                                scale = 1.37;
                            }

                            viewport.viewBox = [0, 0, 612, 792]
                        }

                        /*
                            #Ticket  ADO-60328
                            ex: YY2024_MM09_836246MJ4_P92679_BondCall.pdf , YY2024_MM09_57586P5G7_P92816_BondCall.pdf, YY2024_MM09_54721PAA7_P92822_BondCall.pdf
                        */
                        if (viewport.viewBox && viewport.viewBox.length === 4 && viewport.viewBox[0] === 0 && viewport.viewBox[1] === 0 &&
                            viewport.viewBox[2] === 595  && viewport.viewBox[3]  === 842 ){
                            scale = 1.73;
                        }

                        canvas.width = viewport.width - 32; // added 32 since canvas is overflowing the RightPanel
                        canvas.height = viewport.height;
                        // canvas.style.opacity = 0;

                        /*
                            Ticket ADO-45027: context.clearRect(...) is resetting left/top value of canvas since canvas 
                            is inheriting the values from context.
                            A bug is cauing the value of canvas.left & canvas.top stays zero and causing this issue.
                            Cloned the values here and used them in containe.style to fix the issue.
                        */
                        const leftCanvasClone = canvas.offsetLeft;
                        const topConvasClone = canvas.offsetTop;

                        context.clearRect(0, 0, canvas.width, canvas.height);

                        await page.render({canvasContext: context, viewport, signal}).promise; // Render the page content in the canvas
                        const frameOffsetLeft = pdfContainerRef.current.offsetLeft;
                        const frameOffsetTop = pdfContainerRef.current.offsetTop;
                        const padding = 1;

                        if (!jsonExtractionCoords) {
                            console.log("In loading pdf file, jsonExtractionCoords is null.")
                            return
                        }
                        const jsonExtractionCoordsSize = Object.keys(jsonExtractionCoords).length;
                        const jsonTuplesSize = Object.keys(jsonTuples).length;

                        /* Create overlay div to attach the attribute divs.
                         * This div overlays the canvas and is used to attach the attribute divs.
                        */
                        const container = document.createElement('div');

                        //container.id = `canvas_container_${pageNum}`;
                        container.id = `container_${pageNum}`;
                        container.style.position = 'absolute';
                        container.style.left = `${leftCanvasClone}px`;
                        container.style.top = `${topConvasClone}px`;
                        container.style.width = `${canvas.width}px`;
                        container.style.height = `${canvas.height}px`;
                        pdfContainerRef.current.appendChild(container);

                        canvasMapForOcrRefs.current.set(pageNum, {'scale': scale, 
                                                          'padding': padding, 
                                                          'top':  topConvasClone, 
                                                          'left': leftCanvasClone,
                                                          'canvas': canvas,
                                                          'hasOcr' : false
                                                          }
                                                        );

                        while (container.firstChild) {
                            container.removeChild(container.firstChild);
                        }

                        if (jsonExtractionCoordsSize > 0 && jsonTuplesSize > 0) {
                            addDivTuples(jsonTuples, pageNum, context, scale, padding, viewport, frameOffsetLeft, frameOffsetTop, canvas, pdfContainerRef, 
                                tuplesDivIdsRefs, container, texDivMap, divIdTextMap, canvasMapForOcrRefs);
                            addDivsToPredefinedLocations(jsonExtractionCoords, pageNum, context, scale, padding, viewport, frameOffsetLeft, frameOffsetTop, canvas, pdfContainerRef);
                        }
                    }
                    setShowloadPdfprogress(false);
                    setTextDivs(texDivMap);
                    setDivIdText(divIdTextMap);
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('PDF loading aborted:', error.message);
                    } else {
                        console.error('Error loading PDF:', error);
                    }
                }
            };


            function addDivTuples(jsonTuples, pageNum, context, scale, padding, viewport, frameOffsetLeft, frameOffsetTop, canvas,
                 pdfContainerRef, tuplesDivIdsRefs, container, texDivMap, divIdTextMap, canvasMapForOcrRefs) {
                let index = 0;
                jsonTuples.forEach(([x1, y1, x2, y2, attribute, page]) => {
                    if (page === pageNum) {
                        const canvasEntry = canvasMapForOcrRefs.current.get(pageNum);
                        if (canvasEntry) {
                            canvasEntry.hasOcr = true;
                            canvasMapForOcrRefs.current.set(pageNum, canvasEntry);
                        }

                        context.strokeStyle = 'gray';
                        context.lineWidth = 0.1;
                        x1 = x1 * scale;
                        y1 = y1 * scale;
                        x2 = x2 * scale;
                        y2 = y2 * scale;

                        context.strokeRect(x1 - padding, y1 - padding, x2 - x1 + padding * 2, y2 - y1 + padding);
                        const pdfRect = convertToPdfCoordinates([x1 * scale, y1 * scale, x2 - x1, y2 - y1], viewport);
                        const divTuple = document.createElement('div');
                        divTuple.id = index;
                        divTuple.style.position = 'absolute';
                        divTuple.style.left = `${x1}px`;
                        divTuple.style.top = `${y1}px`;
                        divTuple.style.width = `${x2 - x1}px`;
                        divTuple.style.height = `${pdfRect[3] - pdfRect[1]}px`;
                        divTuple.title = attribute;
                        // divTuple.innerText = attribute;
                        container.appendChild(divTuple);
                        
                        if (searchFeatureChecked){
                            addToTextDivRef(attribute, index, texDivMap, divIdTextMap)
                        }
                        
                        tuplesDivIdsRefs.current.set(index.toString(), attribute);
                    }
                    index = index + 1;
                });
            }

            const addDivsToPredefinedLocations = (jsonExtractionCoords, pageNum, context, scale, padding, viewport, frameOffsetLeft, frameOffsetTop, canvas, pdfContainerRef) => {
                jsonExtractionCoords.forEach(([x1, y1, x2, y2, attribute, pg]) => {
                    if (pg === pageNum) {
                        context.strokeStyle = 'red';
                        context.lineWidth = 0.5;
                        x1 = x1 * scale;
                        y1 = y1 * scale;
                        x2 = x2 * scale;
                        y2 = y2 * scale;
                        context.strokeRect(x1 - padding, y1 - padding, x2 - x1 + padding * 2, y2 - y1 + padding * 2);

                        const pdfRect = convertToPdfCoordinates([x1 * scale, y1 * scale, x2 - x1, y2 - y1], viewport);
                        const div = document.createElement('div');
                        div.id = attribute + '_overlay';
                        div.style.position = 'absolute';
                        div.style.zIndex = -1;
                        if (pg >= 1) {
                            div.style.left = `${pdfRect[0] + frameOffsetLeft}px`;
                            div.style.top = `${pdfRect[1] + frameOffsetTop + 5 + ((pg - 1) * (canvas.height + 3.4))}px`;
                            div.style.width = `${pdfRect[2] - pdfRect[0] + 8}px`;
                            div.style.height = `${pdfRect[3] - pdfRect[1]}px`;
                            div.style.borderBottom = '';
                            div.style.borderTop = '';
                            div.title = attribute; // Set tooltip text

                            div.addEventListener('mouseover', handleMouseOver(attribute));
                            div.addEventListener('mouseout', handleMouseOut(attribute));
                            divRefs.current.push(div);
                        }
                       
                        pdfContainerRef.current.appendChild(div);
                    }
                });
            }

            loadPdfWithAnnotations();
            return () => {
                // Abort the ongoing loading process if the component unmounts
                abortControllerRef.current.abort();

                divRefs.current.forEach((div) => {
                    div.removeEventListener('mouseover', handleMouseOver);
                    div.removeEventListener('mouseout', handleMouseOut);
                });
            };
        },
        [currentPdfFilePath, jsonExtractionCoords]);


    useEffect(() => {
        setCurrentPdfFilePath(selectedFile);
    }, [selectedFile, jsonExtractionCoords]);


    return (
        <Box id='middle-panel' className="middle-panel" style={{textAlign: 'center', backgroundColor: 'white'}}>
             <Box 
                id='middle-panel-main' 
                style={{
                    position: 'relative',
                    overflow: 'auto',
                    minHeight: '93vh',
                    maxHeight: '93vh',
                    scrollBehavior: 'smooth',
                    willChange: 'transform',
                }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}>
                    <Box  id='middle-panel-pdf' style={ isLoading ? {...loadingStyle} : undefined} ref={pdfContainerRef}></Box>
            </Box>
        </Box>
    );
};


function convertToPdfCoordinates(rectangle, viewport) {
    const x = (rectangle[0] / viewport.width) * viewport.viewBox[2] + viewport.viewBox[0];
    const y = (rectangle[1] / viewport.height) * viewport.viewBox[3] + viewport.viewBox[1];
    const width = (rectangle[2] / viewport.width) * viewport.viewBox[2];
    const height = (rectangle[3] / viewport.height) * viewport.viewBox[3];

    return [x, y, x + width, y + height];
}

export default MiddlePanel;